
.logo1{
  width:55px;
}
.logo2{
  width:80px;
}
.logo3{
  width:80px;
}
.section-logo{
margin-top: 60px;
}
.the-name{
  color: green;
  letter-spacing:1px;
  text-shadow: 3px 0px 5px #c7c0c0;;
  font-size:28px;
  text-align: center;
}
.nav{
  border-bottom:2px solid green;
  background-color: #ebefec;
}
.nav-link{
  font-size: 15px;
  font-weight:bolder;
  margin-left: 5px;
  color:black;
}
.nav-link:hover{
  color: #055205;
  cursor: pointer;
}
.homepage{
  background-image: linear-gradient(to right, rgb(245, 218, 218), rgb(243, 238, 238), rgb(192, 223, 192));

}
.line1{
  margin: 5px;
  width:50px;
  height:0.3vh;
  background-color:rgb(15, 121, 15);
  border-radius: 5px 5px 5px 5px;
}
.line2{
  margin: 5px;
  width:35px;
  height:0.3vh;
  background-color:rgb(46, 156, 46);
  border-radius: 5px 5px 5px 5px;
}
.line3{
  margin: 5px;
  width:20px;
  height:0.3vh;
  background-color:rgb(21, 170, 21);
  border-radius: 5px 5px 5px 5px;
}
.whoIs{
  font-size:20px;
  color: green;
  letter-spacing: 1px;
}
.name-title{
  color: #3b4c40;
  margin-top: 4px;
}
.circle-image{
  width:350px;

}
.title-achievement{
  display: flex;
  justify-content: center;
  align-items: center;
  color:green;
  font-size: 23px;
  letter-spacing: 2px;
}
.title-features{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #094c09;
  font-size: 25px;
  letter-spacing: 2px;
}
 .accordion-item{
  border:3px solid #074b07;
  border-radius: 60px;
}

.title-accordion{
color:#074b07;
}

#panelsStayOpen-headingOne{
  background-color: #055205;
  color: #c7c0c0;
}
.icon-features{
  width:40px;
  height: 40px;
  border-radius: 50%;
  background-color: #074b07;
  margin:5px;
  padding: 8px;
}
.title-block-features{
  font-size: 20px;
}
.text-block-features{
  font-size: 15px;
  color: #6c6a6a;
}
.block-footer{
  background-color: #054005;
}
.logo{
  width: 120px;
}
.text-footer{
  color: white;
  font-size: 20px;
}
.footer-link{
  font-size: 15px;
  margin-left: 5px;
  color:white;
  text-decoration-line: none;
}
.icon-footer{
  width: 25px;
  margin:7px;
}
.item-footer{
  font-size: 14px;
  color: white;
}
.icon-photo-footer{
  width: 12px;

}
.data-contact-footer{
  font-size:14px;
  color:white;
}
.icon1-photo-footer{
  width: 11px;
}
.li-item{
  list-style-type: none;
}
.footer-text{
 color: white;
 font-size: 10px;
}
.logoxcore{
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
}

